<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <v-row>
          <v-col class="col-4">
            <h1>Create a new customer</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              :rules="[rules.required]"
              hint="Required"
              persistent-hint
              v-model="customer.name"
              label="Name"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <label>Business Type</label>
            <v-autocomplete
              v-model="customer.business_type_id"
              :items="businessTypes"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              :rules="[rules.required]"
              hint="Required"
              persistent-hint
              v-model="customer.address_1"
              label="Address One"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="customer.address_2"
              label="Address Two"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="customer.address_3"
              label="Address Three"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="customer.address_4"
              label="Address Four"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="customer.address_5"
              label="Address Five"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
               :rules="[rules.required]"
               hint="Required"
               persistent-hint
              v-model="customer.postcode"
              label="Postcode"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="customer.tel"
              label="Telephone"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="customer.email"
              label="Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="customer.accounts_email"
              label="Accounts Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-textarea
              v-model="customer.notes"
              label="Notes"
              hint="These will show when adding a new quotation, i.e. Customer requires a PO"
              persistent-hint></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-btn
              @click="saveCustomer">Save</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'CreateCustomer',
  data() {
    return {
      customer: {
        name: '',
        business_type_id: 0,
        address_1: '',
        postcode: '',
      },
      businessTypes: [],
      message: '',
      showMessage: false,
      rules: {
          required: value => !!value || 'Required.',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
      },
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    saveCustomer() {
      if (
        this.customer.name.length > 0
        && this.customer.address_1.length > 0
        && this.customer.postcode.length > 0
        ) {
        axios.post(`/customers/create.json?token=${this.token}`, this.customer)
          .then((response) => {
            this.$router.push(`/customers/add-contact/${response.data.customer.id}`)
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.message = 'Please complete all required fields: Name, Address One and Postcode';
        this.showMessage = true;
      }
    },
    getBusinessTypes() {
      axios.get(`/businessTypes/getAll.json?token=${this.token}`)
        .then((response) => {
          this.businessTypes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getBusinessTypes();
  },
}
</script>
